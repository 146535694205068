@import "mixins"
@font-face
  font-display: swap
  font-family: "helveticaNeue"
  src: url("/fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-Cn.woff")

@font-face
  font-family: "currency"
  src: url("/fonts/currency/icomoon.ttf"), url("/fonts/currency/icomoon.eot"),url("/fonts/currency/icomoon.svg"), url("/fonts/currency/icomoon.woff")

html body
  background: var(--body_bg)
  color: rgb(var(--primaryColor))
  --subHeader_height: 0px
  &.disable-scroll
    overflow: hidden
    overscroll-behavior: none
  .intl-tel-input.iti-container, &.iti-mobile .intl-tel-input.iti-container
    top: 15%
    bottom: 15%
    max-width: calc(var(--modalMaxWidth) + 20px)
    left: 50%
    transform: translateX(-50%)
    right: unset
    width: calc(100% - 60px)
    @media only screen and (max-height: 575.98px) and (orientation: landscape)
      width: 100%
      top: 10px
      bottom: 10px

  .intl-tel-input .country-list
    background: var(--ddl_listBg)
    border: 0
    border-radius: 3px
    color: #fff
    width: 100%
    .divider
      padding: 0
      margin-bottom: 1px
      border-color: var(--ddl_listBorder)
    .country
      @include flex
      @include ellipsis
      padding: 10px 8px
      color: #fff
      &.highlight
        background-color: var(--ddl_listSelectedBg)
      &:not(:first-of-type)
        border-top: 1px solid var(--ddl_listBorder)
      .country-name
        @include ellipsis
      .dial-code
        white-space: nowrap
        color: rgba(var(--primaryColor), 0.8)

.page,
.modal,
.modalContainer
  h1
    font-size: 18px
    line-height: 24px
  h2
    font-size: 16px
    line-height: 18px
  h3
    font-size: 14px
    line-height: 16px
  ::-webkit-scrollbar-thumb
    height: 5px
    width: 5px
    border-radius: 10px
    background-color: var(--ddl_listBg)
    border: 3px solid var(--body_bg)
  ::-webkit-scrollbar-corner
    background-color: transparent

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active
    -webkit-box-shadow: 0 0 0 30px var(--field_bg) inset !important
    outline: 1px solid var(--field_bg)
    -webkit-text-fill-color: #fff !important
  *
    -webkit-tap-highlight-color: transparent

a.activeLink
  cursor: default !important
  pointer-events: none !important

.page
  width: 100%
  height: 100%
  padding-top: var(--headerHeight)
  padding-bottom: var(--menuHeight)
  font-size: 12px
  .slick-prev,
  .slick-next
    z-index: 1
    width: 26px
    height: 26px
    border-radius: 100%
    border: 1px solid #fff
    background: transparent
    @include flex(true)
    justify-content: center
    &:before
      content: ""
      width: 10px
      height: 5px
      background: var(--icon-arrow) no-repeat
      background-size: 10px 5px
    &:active
      opacity: 0.5

    &.slick-disabled
      opacity: 0.6 !important
      border-color: rgb(var(--base_textPrimaryColor))

  .slick-prev
    left: 16px
    &:before
      transform: rotate(90deg)
      transform-origin: 48% 45%
  .slick-next
    right: 16px
    &:before
      transform: rotate(-90deg)
      transform-origin: 45% 50%
      margin-left: 2px

.ui-checkbox
  display: flex
  input[type="checkbox"]
    position: absolute
    left: -999999px
  > i
    background-color: rgb(var(--primaryColor))
    min-width: 14px
    width: 14px
    height: 14px
    border-radius: 2px
    display: inline-block
    vertical-align: top
    @include flex
    justify-content: center
    margin-right: 10px
  &.checked input[type="checkbox"] + i:before
    content: ""
    width: 10px
    height: 10px
    background-color: var(--txt_color)
    mask-image: var(--icon-checkbox-check)
    mask-size: 10px 10px

.required
  position: relative
  &:before
    content: ""
    position: absolute
    right: 0
    left: unset
    top: 0
    z-index: 1
    height: 16px
    width: 16px
    background-color: rgb(var(--particularColor2))
    border: inherit
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%)
    transform: rotate(180deg)
    border-radius: 0 0 0 3px
  &:after
    content: "*"
    color: #fff
    position: absolute
    right: 3px
    top: 2px
    font-size: 11px
    z-index: 2

.no-data
  @include flex
  flex-direction: column
  height: 100%
  justify-content: center
  @include bold
  font-size: 14px
  text-transform: capitalize
  color: var(--noData_color)

.svg-sport-icon
  min-width: 16px
  height: 16px
  margin-right: 8px
  path
    fill: var(--svgIcon_fill) !important

.svg-region-icon
  min-width: 16px
  height: 13px
  margin-right: 8px

.iti-flag
  min-width: 20px

.no-game
  background: var(--noGame_bg)
  border: 1px dashed var(--noGame_border)
  border-radius: 3px
  padding: 0 10px
  @include flex
  justify-content: center
  height: 32px
  margin: 10px
  color: rgb(var(--primaryColor))

.overflow
  overflow: hidden

.imageNext
  display: flex

.svg-icon,
.svg-icon > *
  @include flex

.crypto-currency
  min-width: 16px
  svg
    width: 16px
    height: 16px

.base-icon-currency-xrp:before
  content: "\e901"
  font-family: var(--specialFontCurrency)
  margin: 0 3px 0 0
  font-size: 8px

.base-icon-currency-trx:before
  content: "\e900"
  font-family: var(--specialFontCurrency)
  margin: 0 3px 0 -1px
  font-size: 11px

.viewPass
  position: absolute
  right: 0
  top: 0
  width: 36px
  height: 100%
  @include flex
  justify-content: center
  &:before
    content: ""
    opacity: 0.8
    width: 16px
    height: 12px
    mask-size: 16px 12px
    background-color: #fff
    mask-image: var(--icon-password-hide)
  &.showPass:before
    mask-image: var(--icon-password-show)

.rc-notification
  &:has(.notification-modal)
    background: var(--backdropBg)
    height: 100%
    width: 100%
    position: fixed
    top: 0
    z-index: 10
  .notification-modal
    height: auto
    padding: 20px
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.002)
    border-radius: 3px
    background: var(--modal_bg)
    width: 90%!important
    overflow: auto

    .rc-notification-notice-content
      > div
        font-size: 16px
        @include bold
        text-transform: uppercase
        color: rgb(var(--particularColor2))
      > p
        position: relative
        padding: 32px 20px 20px
        margin: 0 -20px
        min-height: 114px
        @include flex
        line-height: 20px
        font-size: 15px
        @include bold
        color: #fff

        &:before
          content: ""
          position: absolute
          left: 0
          top: 14px
          width: 100%
          height: 1px
          background: var(--account_borderLight)
          border-top: 1px solid var(--account_borderDark)
          border-bottom: 1px solid var(--account_borderDark)
          box-shadow: 0 1px var(--account_borderLight)
      > button
        width: 100%
    .rc-notification-notice-close
      background: var(--modal_closeIconBg)
      border-radius: 30px
      @include flex
      justify-content: center
      width: 30px
      height: 30px
      position: absolute
      top: 5px
      right: 5px
      z-index: 2
      font-size: 0
      &:before
        content: ""
        mask-image: var(--icon-close)
        width: 10px
        height: 10px
        mask-size: 10px
        background-color: #fff
      &:active
        opacity: 0.8
