@import "mixins"
body
    //icons
    --icon-account-user: url("https://bra.inplay.stream/assets/common/desktop/base-icons/user.svg")
    --icon-account-messages: url("https://bra.inplay.stream/assets/common/desktop/base-icons/account-messages.svg")
    --icon-bet-on-btc: url("https://bra.inplay.stream/assets/common/mobile/betonbtc/bet-on-btc-rules-coin.webp")
    --icon-menu-betonbtc-bg: url("https://bra.inplay.stream/assets/common/mobile/betonbtc/bet-on-btc-bg-pattern.webp")
    --icon-not-found-red-bubble: url("https://bra.inplay.stream/assets/common/mobile/not-found/red-bubble.webp")
    --icon-sir-basketball: url("https://bra.inplay.stream/assets/common/mobile/scoreboards/sir-widget-basketball.webp")
    --icon-sir-soccer: url("https://bra.inplay.stream/assets/common/mobile/scoreboards/sir-widget-soccer.webp")
    --icon-sir-baseball: url("https://bra.inplay.stream/assets/common/mobile/scoreboards/sir-widget-baseball.webp")
    --icon-sir-hockey: url("https://bra.inplay.stream/assets/common/mobile/scoreboards/sir-widget-ice-hockey.webp")
    --icon-sir-table-tennis: url("https://bra.inplay.stream/assets/common/mobile/scoreboards/sir-widget-table-tennis.webp")
    --icon-sir-tennis: url("https://bra.inplay.stream/assets/common/mobile/scoreboards/sir-widget-tennis.webp")
    --icon-sir-volleyball: url("https://bra.inplay.stream/assets/common/mobile/scoreboards/sir-widget-volleyball.webp")
    --icon-sir-futsal: url("https://bra.inplay.stream/assets/common/mobile/scoreboards/sir-widget-futsal.webp")
    --icon-sir-badminton: url("https://bra.inplay.stream/assets/common/mobile/scoreboards/sir-widget-badminton.webp")
    --icon-arrow: url("https://bra.inplay.stream/assets/common/desktop/base-icons/arrow-white.svg")
    --icon-menu-double-arrow: url("https://bra.inplay.stream/assets/common/mobile/base-icons/double-arrow.svg")
    --icon-search-compact: url("https://bra.inplay.stream/assets/common/casino/compact/search.svg")
    --icon-live-scoreboard-overlay: url("https://bra.inplay.stream/assets/common/live-game-scoreboards/overlay.png")
    --icon-live-scoreboard-texture: url("https://bra.inplay.stream/assets/common/live-game-scoreboards/texture.png")
    --icon-live-scoreboard-baseball-texture: url("https://bra.inplay.stream/assets/common/live-game-scoreboards/3/texture.png")
    --icon-live-scoreboard-baseball-board: url("https://bra.inplay.stream/assets/common/live-game-scoreboards/3/board.svg")
    --icon-live-scoreboard-baseball-center: url("https://bra.inplay.stream/assets/common/live-game-scoreboards/3/center.svg")
    --icon-live-scoreboard-handball-goal: url("https://bra.inplay.stream/assets/common/live-game-scoreboards/6/goal.svg")
    --icon-live-scoreboard-soccer-goal: url("https://bra.inplay.stream/assets/common/live-game-scoreboards/1/goal.svg")
    --icon-live-scoreboard-soccer-center: url("https://bra.inplay.stream/assets/common/live-game-scoreboards/1/center.svg")
    --icon-live-scoreboard-darts-center: url("https://bra.inplay.stream/assets/common/live-game-scoreboards/22/center.svg")
    --icon-live-scoreboard-basketball-basket: url("https://bra.inplay.stream/assets/common/live-game-scoreboards/2/basket.svg")
    --icon-live-scoreboard-basketball-center: url("https://bra.inplay.stream/assets/common/live-game-scoreboards/2/center.svg")
    --icon-live-scoreboard-ice-hockey-center: url("https://bra.inplay.stream/assets/common/live-game-scoreboards/4/center.svg")
    --icon-live-scoreboard-ice-hockey-goal: url("https://bra.inplay.stream/assets/common/live-game-scoreboards/4/goal.svg")
    --icon-live-scoreboard-ice-hockey-texture: url("https://bra.inplay.stream/assets/common/live-game-scoreboards/4/texture.png")
    --icon-tab-corner: url("https://bra.inplay.stream/assets/common/desktop/base-icons/tab-corner.svg")
    --icon-password-show: url("https://bra.inplay.stream/assets/common/desktop/base-icons/password-show.svg")
    --icon-password-hide: url("https://bra.inplay.stream/assets/common/desktop/base-icons/password-notshow.svg")
    --icon-password: url("https://bra.inplay.stream/assets/common/mobile/base-icons/login-password.svg")
    --icon-search: url("https://bra.inplay.stream/assets/common/desktop/base-icons/account-search.svg")
    --icon-close: url("https://bra.inplay.stream/assets/common/desktop/base-icons/close-white.svg")
    --icon-checkbox-check: url("https://bra.inplay.stream/assets/common/desktop/base-icons/checkbox-checked-icon.svg")
    --icon-profile-menu-selected-corner: url("https://bra.inplay.stream/assets/common/mobile/base-icons/profile-menu-corner.svg")
    --icon-notification-bg: url("https://bra.inplay.stream/assets/common/notification-image.webp")

    //end of icons
    --specialFont: "helveticaNeue"
    --specialFontCurrency: "currency"
    --headerHeight: var(--header_height)
    --menuHeight: 60px
    --withoutHeaderHeight: calc(100vh - var(--headerHeight) - var(--menuHeight))
    --withHeaderHeight: calc(var(--withoutHeaderHeight) - 92px)
    --modalMaxWidth: 500px
